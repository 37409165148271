// extracted by mini-css-extract-plugin
export var slideshow_container = "Carousel-module--slideshow_container--t44Ix";
export var fadein = "Carousel-module--fadein--xFS+J";
export var loaded = "Carousel-module--loaded--R1fVa";
export var button_prev = "Carousel-module--button_prev--9M3-H";
export var button_next = "Carousel-module--button_next--XNd-T";
export var indicator_container = "Carousel-module--indicator_container--soAPe";
export var indicator = "Carousel-module--indicator--gIBfV";
export var slideshow_image = "Carousel-module--slideshow_image--yMhrV";
export var current = "Carousel-module--current--5QBDz";
export var no_padding = "Carousel-module--no_padding--5qDZK";
export var display_none = "Carousel-module--display_none--z9owh";