// @flow

import * as React from 'react'

import * as styles from './OurCommunity.module.css'

type Props = {
  videoSrc: string,
  quote: string,
  info: string,
}

export function MobileCard(props: Props) {
  const {videoSrc, quote, info} = props

  return (
    <div>
      <div className={styles.mobile_video_container}>
        <p className={styles.quote}>{quote}</p>
        <h5 className={styles.mobile_info}>{info}</h5>
      </div>
      <div className={styles.mobile_customer_video}>
        <iframe
          src={`${videoSrc}?&title=0&byline=0&portrait=0?&title=0&byline=0&portrait=0`}
          width="300"
          height="180"
          frameBorder="0"
        />
      </div>
    </div>
  )
}
