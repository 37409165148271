// @flow
import * as React from 'react'
import {Button} from '../../../ui-components'
import * as styles from './Automation.module.css'

const chatButtonProps = {
  className: styles.contact_button,
  gtmCategory: 'b-footer',
  gtmLabel: 'chat-with-us',
  gtmAction: 'click',
  children: 'chat with us',
}

const demoButtonProps = {
  className: styles.contact_button,
  white: true,
  children: 'schedule a demo',
  Component: 'a',
  linkOutProps: {
    url: 'https://insights.opentrons.com/schedule-demo',
    gtm: {
      action: 'click',
      category: 'b-footer',
      label: 'schedule-demo',
    },
  },
  state: {label: 'automation-demo-bottom', category: 'hp'},
}

export default function AutomationContact() {
  return (
    <section className={styles.automation_wrapper}>
      <div className={styles.automation_content}>
        <h3>Join the automation revolution today</h3>
        <h5>Try out the OT-2 in your lab for 60 days, 100% risk free</h5>
        <div className={styles.button_container}>
          <a
            href="http://support.opentrons.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Button {...chatButtonProps} />
          </a>
          <Button {...demoButtonProps} />
        </div>
      </div>
    </section>
  )
}
