// @flow

import * as React from 'react'
import cx from 'classnames'

import Carousel from '../Carousel'
import {MobileCard} from './MobileCard.js'

import {Button} from '../../ui-components'

import * as styles from './OurCommunity.module.css'

const OUR_COMMUNITY_DATA = [
  {
    quote: `"I've done I don't know how many hundreds of minipreps in my life. I don't want to do another 3,000."`,
    info: 'Nick Emery, DAMP of Boston University',
    video: 'https://player.vimeo.com/video/303374891',
    buttonProps: {
      children: 'view more customer stories',
      className: styles.button,
      Component: 'a',
      linkOutProps: {
        url: 'https://blog.opentrons.com/tag/community/',
        gtm: {
          action: 'click',
          category: 'b-our-community',
          label: 'community-all-1',
        },
      },
    },
  },
  {
    quote: `"There’s two reasons we chose Opentrons: it’s affordable and we can develop open-source software for it."`,
    info: 'Keoni Gandall, Free Genes Project',
    video: 'https://player.vimeo.com/video/290614758',
    buttonProps: {
      children: 'view more customer stories',
      className: styles.button,
      Component: 'a',
      linkOutProps: {
        url: 'https://blog.opentrons.com/tag/community/',
        gtm: {
          action: 'click',
          category: 'b-our-community',
          label: 'community-all-2',
        },
      },
    },
  },
  {
    quote: `"I would just never be able to do as many samples by hand. I would probably lose my mind."`,
    info: 'Kaja Wasik, Gencove',
    video: 'https://player.vimeo.com/video/290691212',
    buttonProps: {
      children: 'view more customer stories',
      className: styles.button,
      Component: 'a',
      linkOutProps: {
        url: 'https://blog.opentrons.com/tag/community/',
        gtm: {
          action: 'click',
          category: 'b-our-community',
          label: 'community-all-3',
        },
      },
    },
  },
]

export type OurCommunityProps = {
  bgColor?: string,
}

export function OurCommunity(props: OurCommunityProps) {
  const {bgColor} = props
  return (
    <div
      className={cx({
        [styles.container]: !bgColor,
        [styles.bg_container]: bgColor,
      })}
    >
      <div className={styles.header_container}>
        <h3 className={styles.h3}>Our Customers</h3>
        <h5 className={styles.h5}>
          Explore how other labs automate with the OT-2
        </h5>
        <div className={styles.desktop_view}>
          <Carousel
            node={CUSTOMER_VIDEOS}
            hasButton={true}
            buttonHeight={'2.5rem'}
            buttonWidth={'auto'}
            noIndicator={true}
          />
        </div>
        <div className={styles.mobile_view}>
          {OUR_COMMUNITY_DATA.map((item, index) => {
            return (
              <MobileCard
                key={index}
                quote={item.quote}
                info={item.info}
                videoSrc={item.video}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const CUSTOMER_VIDEOS = OUR_COMMUNITY_DATA.map((data, i) => {
  return (
    <div key={i} className={styles.video_container}>
      <div>
        <p className={styles.quote}>{data.quote}</p>
        <h5 className={styles.video_user_info}>{data.info}</h5>
        <Button {...data.buttonProps} />
      </div>
      <div className={styles.customer_video}>
        <iframe
          src={`${data.video}?&title=0&byline=0&portrait=0?&title=0&byline=0&portrait=0`}
          width="600"
          height="345"
          frameBorder="0"
        />
      </div>
    </div>
  )
})
