// import {CITY_DATA} from './map-box-data'

import React, {useState} from 'react'
import ReactMapGL, {Marker, NavigationControl} from 'react-map-gl'

import * as styles from './MapBox.module.css'

import {CITY_DATA} from '../CustomerStory/customer-story-data'
import {TEST_API_TOKEN} from './map-box-data'

const mapStyle = 'mapbox://styles/huntermelverton/ck6mpiedn1cu21ipf0hnpq712'

export function MapBox() {
  const [viewport, setViewport] = useState({
    height: 350,
    latitude: 37.10415,
    longitude: -41.11722,
    zoom: 2.5,
  })

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      onViewportChange={setViewport}
      mapboxApiAccessToken={TEST_API_TOKEN}
      mapStyle={mapStyle}
      trackResize={true}
      scrollZoom={false}
    >
      {CITY_DATA.map((data, i) => {
        return (
          <Marker
            key={i}
            latitude={data.lat}
            longitude={data.lng}
            offsetLeft={5}
          >
            <div className={styles.marker} />
          </Marker>
        )
      })}
      <NavigationControl
        captureScroll={false}
        captureDrag={false}
        style={{right: 0}}
      />
    </ReactMapGL>
  )
}
