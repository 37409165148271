import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import CustomerStory from '../components/CustomerStory'

const CustomerStoryPage = () => (
  <Page>
    <SEO
      title="Opentrons Labworks | Our Customers"
      description="See what labs like yours are automating with Opentrons. From academic researchers to top 10 pharma, labs of all sizes use Opentrons to automate their experiments to save time, eliminate sources of data, and free up researchers to do less manual/tedious work."
    />
    <CustomerStory />
  </Page>
)

export default CustomerStoryPage
