// extracted by mini-css-extract-plugin
export var container = "OurCommunity-module--container--M88c7";
export var bg_container = "OurCommunity-module--bg_container--hc0hR";
export var h3 = "OurCommunity-module--h3--fb6zl";
export var h5 = "OurCommunity-module--h5--2XQJ0";
export var p = "OurCommunity-module--p--Y4jjT";
export var button = "OurCommunity-module--button--9qygV";
export var header_container = "OurCommunity-module--header_container--T-+g8";
export var video_container = "OurCommunity-module--video_container--lXdBd";
export var video_user_info = "OurCommunity-module--video_user_info--DiH8q";
export var mobile_info = "OurCommunity-module--mobile_info--zEP-n";
export var customer_video = "OurCommunity-module--customer_video--KXr0r";
export var mobile_video_container = "OurCommunity-module--mobile_video_container--A3um4";
export var mobile_customer_video = "OurCommunity-module--mobile_customer_video--Ir3SO";
export var quote = "OurCommunity-module--quote--saPvY";
export var desktop_view = "OurCommunity-module--desktop_view--LvvvP";
export var mobile_view = "OurCommunity-module--mobile_view--0ROP9";