// extracted by mini-css-extract-plugin
export var h2 = "CustomerStory-module--h2--1rWIj";
export var color_bg = "CustomerStory-module--color_bg--CAv9M";
export var h3 = "CustomerStory-module--h3--mpWk3";
export var header_3 = "CustomerStory-module--header_3--IQUEK";
export var h5 = "CustomerStory-module--h5--3Kuiq";
export var h6 = "CustomerStory-module--h6--kCZs5";
export var p = "CustomerStory-module--p--rS2Ry";
export var container = "CustomerStory-module--container--oJ54d";
export var info_container = "CustomerStory-module--info_container--3G5BS";
export var header_container = "CustomerStory-module--header_container--gKi-J";
export var publication_container = "CustomerStory-module--publication_container--ZMq2e";
export var grey_bg = "CustomerStory-module--grey_bg--nMMr5";
export var link = "CustomerStory-module--link--ZGMHm";
export var button = "CustomerStory-module--button---Kqi4";
export var desktop_view = "CustomerStory-module--desktop_view--0UgYd";
export var flex_wrap = "CustomerStory-module--flex_wrap--4u2mg";
export var mobile_view = "CustomerStory-module--mobile_view--mOh9x";
export var map_mobile_view = "CustomerStory-module--map_mobile_view--rH3r0";
export var tablet_view = "CustomerStory-module--tablet_view--kfHMf";
export var quotes_container = "CustomerStory-module--quotes_container--4K5H+";
export var quotes_data_container = "CustomerStory-module--quotes_data_container--2RZ2m";