// @flow

import * as React from 'react'

import * as styles from './CustomerStory.module.css'

export type PublicationProps = {
  intuition: string,
  title: string,
  date: string,
  url: string,
}

export default function Publication(props: PublicationProps) {
  const {intuition, title, url, date} = props

  return (
    <div className={styles.publication_container}>
      <h6 className={styles.h6}>{intuition}</h6>
      <p className={styles.p}>
        <a className={styles.link} href={url}>
          {title}
        </a>
      </p>
      <h6 className={styles.h6}>{date}</h6>
    </div>
  )
}
