// @flow

import * as React from 'react'
import cx from 'classnames'

import Publication from './Publication'
import {MapBox} from '../MapBox'
import Carousel from '../Carousel'
import {OurCommunity} from '../OurCommunity'
import AutomationContact from '../homepage/AutomationContact'
import {Button, QuoteCard} from '../../ui-components'

import IMAGE_MAP from './assets/mobile_414x300@2x.jpg'
import TABLET_IMAGE_MAP from './assets/tablet-map_768x300@2x.jpg'

import {PUBLICATION_DATA, QUOTE_ICON_DATA} from './customer-story-data'

import * as styles from './CustomerStory.module.css'

const PublicationButtonProps = {
  children: 'See all',
  Component: 'a',
  className: styles.button,
  linkOutProps: {
    url: 'https://blog.opentrons.com/citations/',
    gtm: {
      action: 'click',
      category: 'b-customer',
      label: 'all-articles',
    },
  },
}

export default function CustomerStory() {
  const QUOTE_CARD = QUOTE_ICON_DATA.map((data, index) => {
    return <QuoteCard key={index} {...data} />
  })

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header_container}>
          <h2 className={styles.h2}>See how other labs use Opentrons</h2>
          <h5 className={styles.h5}>
            Explore the latest publications, reviews and customer stories
          </h5>
        </div>
      </div>
      <div className={styles.grey_bg}>
        <div className={styles.container}>
          <h3 className={styles.h3}>Publications</h3>
          {PUBLICATION_DATA.map((data, i) => {
            return <Publication key={i} {...data} />
          })}
          <Button {...PublicationButtonProps} />
        </div>
        <div className={styles.info_container}>
          <h3 className={styles.header_3}>Find us in 40+ countries</h3>
          <h5 className={styles.h5}>
            See if a lab near you is using Opentrons
          </h5>
        </div>
      </div>
      <div className={styles.desktop_view}>
        <MapBox />
      </div>
      <div className={styles.tablet_view}>
        <img src={TABLET_IMAGE_MAP} alt="" />
      </div>
      <div className={styles.map_mobile_view}>
        <img src={IMAGE_MAP} alt="" />
      </div>
      <div className={styles.color_bg}>
        <div className={styles.quotes_container}>
          <h3 className={styles.h3}>
            Hear what people have to say about Opentrons
          </h3>
          <div className={styles.quotes_data_container}>
            <div className={cx(styles.desktop_view, styles.flex_wrap)}>
              {QUOTE_CARD}
            </div>
            <div className={styles.mobile_view}>
              <Carousel node={QUOTE_CARD} noPadding={true} />
            </div>
          </div>
        </div>
      </div>
      <OurCommunity />
      <AutomationContact />
    </>
  )
}
