// @flow
import * as React from 'react'
import {useSwipeable} from 'react-swipeable'
import cx from 'classnames'
import {IconButton} from '../../ui-components'
import * as styles from './Carousel.module.css'

type Props = {
  node: Array<React.Node>,
  hasButton?: boolean,
  buttonWidth?: string,
  buttonHeight?: string,
  noIndicator?: boolean,
  noPadding?: boolean,
}

export default function Carousel(props: Props) {
  const [index, setIndex] = React.useState(0)
  const {node, hasButton, buttonWidth, buttonHeight, noPadding, noIndicator} =
    props
  const length = node.length - 1
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1)
  const childrenSrc = node[index]
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })
  const useWidth = buttonWidth || '1rem'
  const useHeight = buttonHeight || '1.25rem'
  return (
    <div className={styles.slideshow_container} {...handlers}>
      {/* If developer want you can have arrow-left and arrow-right using the HasButton prop that is a boolean and default type is false */}
      {hasButton && (
        <>
          <div className={styles.button_prev}>
            <IconButton
              iconName="carousel-left"
              width={useWidth}
              height={useHeight}
              onClick={() => handlePrevious()}
            />
          </div>

          <div className={styles.button_next}>
            <IconButton
              iconName="carousel-right"
              width={useWidth}
              height={useHeight}
              onClick={() => handleNext()}
            />
          </div>
        </>
      )}
      {childrenSrc}
      <div
        className={cx(styles.indicator_container, {
          [styles.no_padding]: noPadding,
          [styles.display_none]: noIndicator,
        })}
      >
        {node.map((k, i) => {
          return (
            <span
              className={cx(styles.indicator, {
                [styles.current]: index === i,
              })}
              onClick={() => setIndex(i)}
              key={i}
            />
          )
        })}
      </div>
    </div>
  )
}
