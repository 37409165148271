// @flow

import type {PublicationProps} from './Publication'
import type {QuoteCardProps} from '../../ui-components'

export const PUBLICATION_DATA: Array<PublicationProps> = [
  {
    intuition: 'Center For Disease Control',
    title:
      'Rapid Emergence and Epidemiologic Characteristics of the SARS-CoV-2 B.1.526 Variant',
    url: 'https://www.cdc.gov/mmwr/volumes/70/wr/mm7019e1.htm',
    date: 'May 2021',
  },
  {
    intuition: 'Hospital Clinic De Barcelona',
    title:
      'ROBOCOV: An Affordable Open-Source Robotic Platform For SARS-CoV-2 Testing By RT-qPCR',
    url: 'https://www.biorxiv.org/content/10.1101/2020.06.11.140285v2',
    date: 'June 2020',
  },
  {
    intuition: `King's College London`,
    title:
      'CONTAIN: An Open-Source Shipping Container Laboratory Optimised For Automated COVID-19 Diagnostics',
    url: 'https://www.biorxiv.org/content/10.1101/2020.05.20.106625v1',
    date: 'May 2020',
  },
  {
    intuition: 'Imperial College London',
    title:
      'DNA-BOT: A Low-Cost, Automated DNA Assembly Platform For Synthetic Biology',
    url: 'https://www.biorxiv.org/content/10.1101/832139v1',
    date: 'Nov 2019',
  },
  {
    intuition: 'Stanford University',
    title: 'Synthetic Genetic Codes Designed to Hinder Evolution',
    url: 'https://doi.org/10.1101/695569',
    date: 'July 2019',
  },
]

export const CITY_DATA = [
  {lat: -35.2852323, lng: 149.1129681, city: 'Canberra'},
  {lat: -36.1257017, lng: 146.848633, city: 'Wodonga'},
  {lat: -27.548375, lng: 153.0474942, city: 'undefined'},
  {lat: -38.2037278, lng: 144.3022236, city: 'Geelong'},
  {lat: -37.7871148, lng: 144.9515533, city: 'undefined'},
  {lat: -31.9527121, lng: 115.8604796, city: 'Perth'},
  {lat: -33.8794728, lng: 151.1984346, city: 'Sydney'},
  {lat: -27.5615314, lng: 152.2791353, city: 'undefined'},
  {lat: -31.9866645, lng: 115.8832165, city: 'Perth'},
  {lat: -34.0400221, lng: 150.9986588, city: 'Sydney'},
  {lat: -28.793639, lng: 153.2670868, city: 'undefined'},
  {lat: -27.4689682, lng: 153.0234991, city: 'Brisbane'},
  {lat: -37.6973056, lng: 145.0662544, city: 'undefined'},
  {lat: -27.5693838, lng: 153.0057324, city: 'Brisbane'},
  {lat: 47.33333, lng: 13.33333, city: 'undefined'},
  {lat: 48.2083537, lng: 16.3725042, city: 'Vienna'},
  {lat: -34.9281805, lng: 138.5999312, city: 'Adelaide'},
  {lat: -31.8759835, lng: 147.2869493, city: 'undefined'},
  {lat: 47.2000338, lng: 13.199959, city: 'undefined'},
  {lat: 50.879202, lng: 4.7011675, city: 'Leuven'},
  {lat: 47.0708678, lng: 15.4382786, city: 'Graz'},
  {lat: 50.8517292, lng: 4.6931308, city: 'Leuven'},
  {lat: 51.0233485, lng: 5.2750485, city: 'Heusden-Zolder'},
  {lat: 50.9303735, lng: 5.3378043, city: 'Hasselt'},
  {lat: 42.2858536, lng: -82.9780695, city: 'undefined'},
  {lat: 50.6402809, lng: 4.6667145, city: 'undefined'},
  {lat: 49.2608724, lng: -123.1139529, city: 'Vancouver'},
  {lat: -23.5506507, lng: -46.6333824, city: 'São Paulo'},
  {lat: 49.2608724, lng: -123.1139529, city: 'Vancouver'},
  {lat: 43.653963, lng: -79.387207, city: 'Toronto'},
  {lat: 43.653963, lng: -79.387207, city: 'Toronto'},
  {lat: 52.131802, lng: -106.660767, city: 'Saskatoon'},
  {lat: 43.1579812, lng: -79.2441003, city: 'St. Catharines'},
  {lat: 52.131802, lng: -106.660767, city: 'Saskatoon'},
  {lat: 45.8999177, lng: -64.3655256, city: 'Sackville'},
  {lat: 60.10867, lng: -113.64258, city: 'undefined'},
  {lat: 43.880078, lng: -79.439392, city: 'Richmond Hill'},
  {lat: 60.10867, lng: -113.64258, city: 'undefined'},
  {lat: 46.8259601, lng: -71.2352226, city: 'Quebec City'},
  {lat: 46.50011, lng: -62.99868, city: 'undefined'},
  {lat: 49.2197385, lng: -122.5998426, city: 'Maple Ridge'},
  {lat: 45.421106, lng: -75.690308, city: 'Ottawa'},
  {lat: 42.988576, lng: -81.246643, city: 'London'},
  {lat: 52.4727515, lng: -113.733215, city: 'undefined'},
  {lat: 49.1202942, lng: -122.6593311, city: 'Langley Township'},
  {lat: 44.230687, lng: -76.481323, city: 'Kingston'},
  {lat: 44.230687, lng: -76.481323, city: 'Kingston'},
  {lat: 42.2354142, lng: -82.7120106, city: 'undefined'},
  {lat: 43.533783, lng: -80.2306337, city: 'undefined'},
  {lat: 53.535411, lng: -113.507996, city: 'undefined'},
  {lat: 53.535411, lng: -113.507996, city: 'undefined'},
  {lat: 46.234953, lng: -63.132935, city: 'Charlottetown'},
  {lat: 51.0534234, lng: -114.0625892, city: 'undefined'},
  {lat: 51.0534234, lng: -114.0625892, city: 'undefined'},
  {lat: 51.0534234, lng: -114.0625892, city: 'undefined'},
  {lat: 43.3248924, lng: -79.7966835, city: 'Burlington'},
  {lat: 55.001251, lng: -125.002441, city: 'undefined'},
  {lat: 55.001251, lng: -97.001038, city: 'undefined'},
  {lat: 43.1654577, lng: -79.4751839, city: 'undefined'},
  {lat: -33.47269, lng: -70.64724, city: 'undefined'},
  {lat: -33.447373, lng: -70.5334118, city: 'La Reina'},
  {lat: 61.0666922, lng: -107.9917071, city: 'undefined'},
  {lat: 22.555454, lng: 114.0543297, city: 'Shenzhen'},
  {lat: 24.4758496, lng: 118.0746834, city: 'Xiamen City'},
  {lat: 22.555454, lng: 114.0543297, city: 'Shenzhen'},
  {lat: 31.2252985, lng: 121.4890497, city: 'Shanghai'},
  {lat: 31.2217826, lng: 121.5387401, city: 'Pudong'},
  {lat: 32.0609736, lng: 118.7916458, city: 'undefined'},
  {lat: 30.6624205, lng: 104.0633219, city: 'Chengdu'},
  {lat: 22.2793278, lng: 114.1628131, city: 'Hong Kong'},
  {lat: 39.906217, lng: 116.3912757, city: 'Dongcheng District'},
  {lat: 28.2, lng: 87.7, city: 'undefined'},
  {lat: 31.2252985, lng: 121.4890497, city: 'Shanghai'},
  {lat: 34.9236095, lng: 33.6236184, city: 'Larnaca'},
  {lat: 45.813177, lng: 15.977048, city: 'Zagreb'},
  {lat: 49.75, lng: 15, city: 'undefined'},
  {lat: 49.8167003, lng: 15.4749544, city: 'undefined'},
  {lat: 49.75, lng: 15, city: 'undefined'},
  {lat: 55.6433478, lng: 12.0819247, city: 'Roskilde'},
  {lat: 56, lng: 10, city: 'undefined'},
  {lat: 55.3997225, lng: 10.3852104, city: 'Odense'},
  {lat: 55.7718649, lng: 12.5051413, city: 'undefined'},
  {lat: 55.7718649, lng: 12.5051413, city: 'undefined'},
  {lat: 56, lng: 10, city: 'undefined'},
  {lat: 55.6441712, lng: 12.4772091, city: 'undefined'},
  {lat: 56, lng: 10, city: 'undefined'},
  {lat: 56, lng: 10, city: 'undefined'},
  {lat: 56, lng: 10, city: 'undefined'},
  {lat: 55.6835672, lng: 12.4128011, city: 'undefined'},
  {lat: 55.678016, lng: 12.5326186, city: 'undefined'},
  {lat: 56.1532111, lng: 10.1024171, city: 'undefined'},
  {lat: 55.7583936, lng: 12.4573112, city: 'undefined'},
  {lat: 56.1496278, lng: 10.2134046, city: 'Aarhus'},
  {lat: 55.670249, lng: 10.3333283, city: 'undefined'},
  {lat: 51.5073219, lng: -0.1276474, city: 'London'},
  {lat: 60.1674098, lng: 24.9425769, city: 'Helsinki'},
  {lat: 61.4980214, lng: 23.7603118, city: 'Tampere'},
  {lat: 64, lng: 26, city: 'undefined'},
  {lat: 46, lng: 2, city: 'undefined'},
  {lat: 43.6044622, lng: 1.4442469, city: 'Toulouse'},
  {lat: 46, lng: 2, city: 'undefined'},
  {lat: 43.6044622, lng: 1.4442469, city: 'Toulouse'},
  {lat: 48.8566969, lng: 2.3514616, city: 'Paris'},
  {lat: 50.4573687, lng: 1.6651851, city: 'Saint-Aubin'},
  {lat: 47.2186371, lng: -1.5541362, city: 'Nantes'},
  {lat: 50.4638918, lng: 1.7631125, city: 'Montreuil'},
  {lat: 43.6112422, lng: 3.8767337, city: 'Montpellier'},
  {lat: 48.8125893, lng: 2.3570028, city: 'Le Kremlin-Bicêtre'},
  {lat: 48.5419351, lng: 7.4924679, city: 'Molsheim'},
  {lat: 46, lng: 2, city: 'undefined'},
  {lat: 45.1875602, lng: 5.7357819, city: 'Grenoble'},
  {lat: 46.603354, lng: 1.8883335, city: 'undefined'},
  {lat: 49.7247617, lng: 8.612855, city: 'Zwingenberg'},
  {lat: 53.5278793, lng: 8.106301, city: 'undefined'},
  {lat: 48.3974003, lng: 9.9934336, city: 'undefined'},
  {lat: 48.5236164, lng: 9.0535531, city: 'Tübingen'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 48.103742, lng: 11.4220029, city: 'Planegg'},
  {lat: 49.0195333, lng: 12.0974869, city: 'Regensburg'},
  {lat: 49, lng: 11.5, city: 'undefined'},
  {lat: 51.21895, lng: 6.76339, city: 'undefined'},
  {lat: 49, lng: 11.5, city: 'undefined'},
  {lat: 48.1371079, lng: 11.5753822, city: 'undefined'},
  {lat: 51.6485843, lng: 7.0829054, city: 'Marl'},
  {lat: 50.8117327, lng: 8.7757742, city: 'Marburg'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 49, lng: 11.5, city: 'undefined'},
  {lat: 50.0012314, lng: 8.2762513, city: 'Mainz'},
  {lat: 49.4704113, lng: 8.4381568, city: 'Ludwigshafen am Rhein'},
  {lat: 53.866444, lng: 10.684738, city: 'Lübeck'},
  {lat: 49.4704113, lng: 8.4381568, city: 'Ludwigshafen am Rhein'},
  {lat: 51.0324743, lng: 6.9881194, city: 'Leverkusen'},
  {lat: 51.3406321, lng: 12.3747329, city: 'Leipzig'},
  {lat: 51.21895, lng: 6.76339, city: 'undefined'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 51.21895, lng: 6.76339, city: 'undefined'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 51.1674417, lng: 6.9307271, city: 'Hilden'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 53.550341, lng: 10.000654, city: 'Hamburg'},
  {lat: 52.83333, lng: 9.16667, city: 'undefined'},
  {lat: 53.550341, lng: 10.000654, city: 'Hamburg'},
  {lat: 49.99427, lng: 8.29447, city: 'undefined'},
  {lat: 50.1106444, lng: 8.6820917, city: 'Frankfurt'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 48.0765786, lng: 11.9647521, city: 'Ebersberg'},
  {lat: 51.5142273, lng: 7.4652789, city: 'Dortmund'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 52.2646577, lng: 10.5236066, city: 'undefined'},
  {lat: 51.5, lng: 10.5, city: 'undefined'},
  {lat: 52.5170365, lng: 13.3888599, city: 'Berlin'},
  {lat: 50.776351, lng: 6.083862, city: 'Aachen'},
  {lat: 51.0834196, lng: 10.4234469, city: 'undefined'},
  {lat: 52.5170365, lng: 13.3888599, city: 'Berlin'},
  {lat: 22.2973142, lng: 73.1942567, city: 'Vadodara'},
  {lat: 47.4983815, lng: 19.0404707, city: 'Budapest'},
  {lat: 13.020817, lng: 80.2239536, city: 'Chennai'},
  {lat: 12.9791198, lng: 77.5912997, city: 'Bengaluru'},
  {lat: 53.2214677, lng: -6.6574093, city: 'undefined'},
  {lat: 12.9791198, lng: 77.5912997, city: 'Bengaluru'},
  {lat: 19.0308262, lng: 73.0198537, city: 'Navi Mumbai'},
  {lat: 22.3511148, lng: 78.6677428, city: 'undefined'},
  {lat: 53.3497645, lng: -6.2602732, city: 'undefined'},
  {lat: 53.4064294, lng: -6.2871015, city: 'undefined'},
  {lat: 31.8952532, lng: 34.8105616, city: 'Rehovot'},
  {lat: 31.9085744, lng: 35.0069297, city: 'Modiin-Maccabim-Reut'},
  {lat: 53.6982695, lng: -8.2182508, city: 'undefined'},
  {lat: 31.778345, lng: 35.2250786, city: 'Jerusalem'},
  {lat: 31.9085744, lng: 35.0069297, city: 'Modiin-Maccabim-Reut'},
  {lat: 31.778345, lng: 35.2250786, city: 'Jerusalem'},
  {lat: 31.5313113, lng: 34.8667654, city: 'undefined'},
  {lat: 31.7900763, lng: 35.2029687, city: 'Jerusalem'},
  {lat: 32.8191218, lng: 34.9983856, city: 'Haifa'},
  {lat: 45.5, lng: 11.75, city: 'undefined'},
  {lat: 46.0634632, lng: 13.2358377, city: 'Udine'},
  {lat: 40.7778004, lng: 8.921997, city: 'undefined'},
  {lat: 45.4077172, lng: 11.8734455, city: 'Padua'},
  {lat: 45.4943063, lng: 12.2418053, city: 'Mestre'},
  {lat: 45.4077172, lng: 11.8734455, city: 'Padua'},
  {lat: 46.1074737, lng: 11.1100404, city: 'Trento'},
  {lat: 43.5219801, lng: 13.2443764, city: 'Jesi'},
  {lat: 46.1074737, lng: 11.1100404, city: 'Trento'},
  {lat: 43.7698712, lng: 11.2555757, city: 'Florence'},
  {lat: 34.764884, lng: 135.51735, city: 'undefined'},
  {lat: 36.5748441, lng: 139.2394179, city: 'undefined'},
  {lat: 54.6870458, lng: 25.2829111, city: 'Vilnius'},
  {lat: 54.8982139, lng: 23.9044817, city: 'Kaunas'},
  {lat: 35.6895, lng: 139.69171, city: 'undefined'},
  {lat: 44.4936714, lng: 11.3430347, city: 'Bologna'},
  {lat: -13.9875107, lng: 33.768144, city: 'Lilongwe'},
  {lat: 52.713968, lng: 4.741541, city: 'undefined'},
  {lat: 23, lng: -102, city: 'undefined'},
  {lat: 52.0809856, lng: 5.127684, city: 'Utrecht'},
  {lat: 51.93833, lng: 5.93333, city: 'undefined'},
  {lat: 52.1931084, lng: 4.6255204, city: 'Kaag en Braassem'},
  {lat: 52.7577195, lng: 5.3206941, city: 'undefined'},
  {lat: 52.1518157, lng: 4.4811089, city: 'Leiden'},
  {lat: 52.7577195, lng: 5.3206941, city: 'undefined'},
  {lat: 51.9994572, lng: 4.3627245, city: 'Delft'},
  {lat: 51.44083, lng: 5.47778, city: 'undefined'},
  {lat: -41.2887953, lng: 174.7772114, city: 'Wellington'},
  {lat: 52.25, lng: 5.75, city: 'undefined'},
  {lat: 52.3745403, lng: 4.8979755, city: 'Amsterdam'},
  {lat: -45.8739282, lng: 170.503488, city: 'Dunedin'},
  {lat: -36.852095, lng: 174.7631803, city: 'Auckland'},
  {lat: 62.7378836, lng: 7.1589568, city: 'undefined'},
  {lat: 63.3508348, lng: 10.357723, city: 'Trondheim'},
  {lat: 60.3943055, lng: 5.3259192, city: 'Bergen'},
  {lat: 59.906664, lng: 10.7363495, city: 'Oslo'},
  {lat: 62, lng: 10, city: 'undefined'},
  {lat: 60.3717643, lng: 5.3421511, city: 'Bergen'},
  {lat: 69.8699347, lng: 21.8272785, city: 'undefined'},
  {lat: 7.0644576, lng: 125.6083647, city: 'Davao City'},
  {lat: 24.8667795, lng: 67.0311286, city: 'Karachi'},
  {lat: 52, lng: 20, city: 'undefined'},
  {lat: 52.2319581, lng: 21.0067249, city: 'Warsaw'},
  {lat: 52.2319581, lng: 21.0067249, city: 'Warsaw'},
  {lat: 49.75, lng: 20.5, city: 'undefined'},
  {lat: 52.25, lng: 17, city: 'undefined'},
  {lat: 52, lng: 20, city: 'undefined'},
  {lat: 50.5, lng: 18, city: 'undefined'},
  {lat: 52, lng: 20, city: 'undefined'},
  {lat: 54.25, lng: 18, city: 'undefined'},
  {lat: 46, lng: 25, city: 'undefined'},
  {lat: 55.7504461, lng: 37.6174943, city: 'Moscow'},
  {lat: 38.7077507, lng: -9.1365919, city: 'Lisbon'},
  {lat: 22.2833454, lng: 39.1128145, city: 'Thuwal'},
  {lat: 1.340863, lng: 103.8303918, city: 'Singapore'},
  {lat: 45.9303401, lng: 13.6398587, city: 'undefined'},
  {lat: 46.0499803, lng: 14.5068602, city: 'Ljubljana'},
  {lat: 1.340863, lng: 103.8303918, city: 'Singapore'},
  {lat: -25.9598881, lng: 28.132456, city: 'undefined'},
  {lat: -33.9319444, lng: 18.4655556, city: 'Cape Town'},
  {lat: -26.0930844, lng: 28.1296278, city: 'Sandton'},
  {lat: 37.2405741, lng: 127.1785572, city: 'undefined'},
  {lat: 37.5649826, lng: 126.9392108, city: 'Sanggye 3·4(sam·sa)-dong'},
  {lat: -29.861825, lng: 31.009909, city: 'Durban'},
  {lat: 36.5, lng: 127.75, city: 'undefined'},
  {lat: 37.4201556, lng: 127.1262092, city: 'undefined'},
  {lat: 36.77431, lng: 126.75719, city: 'Dangjin-si'},
  {lat: 36.3320506, lng: 127.4338196, city: 'Daejeon'},
  {lat: 36.5581914, lng: 127.9408564, city: 'undefined'},
  {lat: 36.64337, lng: 127.5668, city: 'undefined'},
  {lat: 36.3496, lng: 127.3848, city: 'undefined'},
  {lat: 37.5649826, lng: 126.9392108, city: 'Sanggye 3·4(sam·sa)-dong'},
  {lat: 41.1172364, lng: 1.2546057, city: 'Tarragona'},
  {lat: 43.3271048, lng: -2.9846079, city: 'Leioa'},
  {lat: 40.4167047, lng: -3.7035825, city: 'Madrid'},
  {lat: 40.4167047, lng: -3.7035825, city: 'Madrid'},
  {lat: 40, lng: -4, city: 'undefined'},
  {lat: 40, lng: -4, city: 'undefined'},
  {lat: 40, lng: -4, city: 'undefined'},
  {lat: 41.3828939, lng: 2.1774322, city: 'Barcelona'},
  {lat: 59.8586126, lng: 17.6387436, city: 'Uppsala'},
  {lat: 62, lng: 15, city: 'undefined'},
  {lat: 57.7072326, lng: 11.9670171, city: 'Gothenburg'},
  {lat: 62, lng: 15, city: 'undefined'},
  {lat: 59.6356115, lng: 17.077856, city: 'undefined'},
  {lat: 62, lng: 15, city: 'undefined'},
  {lat: 59.6749712, lng: 14.5208584, city: 'undefined'},
  {lat: 47.3723941, lng: 8.5423328, city: 'Zurich'},
  {lat: 46.2302151, lng: 7.3637052, city: 'undefined'},
  {lat: 62, lng: 15, city: 'undefined'},
  {lat: 47.3965147, lng: 8.4444861, city: 'Schlieren'},
  {lat: 46.4937934, lng: 6.8716012, city: 'Corsier-sur-Vevey'},
  {lat: 47.4935369, lng: 7.5910891, city: 'Reinach'},
  {lat: 46.9482713, lng: 7.4514512, city: 'Bern'},
  {lat: 47.5581077, lng: 7.5878261, city: 'Basel'},
  {lat: 47.5581077, lng: 7.5878261, city: 'Basel'},
  {lat: 24.163162, lng: 120.6478282, city: 'Taichung'},
  {lat: 25.0375198, lng: 121.5636796, city: 'Taipei'},
  {lat: 13.8887012, lng: 100.5580237, city: 'Bangkok'},
  {lat: 50.4500336, lng: 30.5241361, city: 'Kyiv'},
  {lat: 53.9590555, lng: -1.0815361, city: 'York'},
  {lat: 51.7520131, lng: -1.2578499, city: 'Oxford'},
  {lat: 54.25, lng: -4.5, city: 'undefined'},
  {lat: 54.9738474, lng: -1.6131572, city: 'Newcastle upon Tyne'},
  {lat: 51.5073219, lng: -0.1276474, city: 'London'},
  {lat: 53.3380232, lng: -2.6097651, city: 'undefined'},
  {lat: 51.7324107, lng: -2.3645385, city: 'undefined'},
  {lat: 54.7023545, lng: -3.2765753, city: 'undefined'},
  {lat: 52.2034823, lng: 0.1235817, city: 'Cambridge'},
  {lat: 53.4794892, lng: -2.2451148, city: 'Manchester'},
  {lat: 51.5449509, lng: -0.4816672, city: 'undefined'},
  {lat: 52.0549666, lng: 0.2211611, city: 'Uttlesford'},
  {lat: 51.5364952, lng: -0.3225887, city: 'London'},
  {lat: 53.3806626, lng: -1.4702278, city: 'Sheffield'},
  {lat: 52.1049388, lng: 0.2249216, city: 'South Cambridgeshire'},
  {lat: 52.2034823, lng: 0.1235817, city: 'Cambridge'},
  {lat: 55.920461, lng: -4.3332046, city: 'undefined'},
  {lat: 52.7954791, lng: -0.5402403, city: 'undefined'},
  {lat: 50.725562, lng: -3.5269108, city: 'Exeter'},
  {lat: 56.7861112, lng: -4.1140518, city: 'undefined'},
  {lat: 45.896342, lng: -89.699037, city: 'undefined'},
  {lat: 40.7991766, lng: -81.9383187, city: 'Wooster'},
  {lat: 42.2625932, lng: -71.8022934, city: 'Worcester'},
  {lat: 40.25034, lng: -83.00018, city: 'undefined'},
  {lat: 47.7545827, lng: -122.1588902, city: 'Woodinville'},
  {lat: 36.0998131, lng: -80.2440518, city: 'Winston-Salem'},
  {lat: 39.8366528, lng: -105.0372046, city: 'Westminster'},
  {lat: 29.5376944, lng: -95.1220967, city: 'Webster'},
  {lat: 34.2257282, lng: -77.9447107, city: 'Wilmington'},
  {lat: 40.2112241, lng: -74.0359713, city: 'undefined'},
  {lat: 36.9092773, lng: -121.7529071, city: 'Watsonville'},
  {lat: 42.3709299, lng: -71.1828321, city: 'Watertown'},
  {lat: 46.2055155, lng: -118.5174284, city: 'undefined'},
  {lat: 45.6306954, lng: -122.6744557, city: 'Vancouver'},
  {lat: 42.3756401, lng: -71.2358004, city: 'Waltham'},
  {lat: 40.1117174, lng: -88.207301, city: 'Urbana'},
  {lat: 38.3565773, lng: -121.9877444, city: 'Vacaville'},
  {lat: 40.7003793, lng: -73.5929056, city: 'Uniondale'},
  {lat: 37.9020731, lng: -122.0618702, city: 'Walnut Creek'},
  {lat: 40.869543, lng: -72.8867697, city: 'undefined'},
  {lat: 40.6652251, lng: -74.3045277, city: 'undefined'},
  {lat: 36.2516475, lng: -118.852583, city: 'undefined'},
  {lat: 32.2228765, lng: -110.9748477, city: 'Tucson'},
  {lat: 40.6652251, lng: -74.3045277, city: 'undefined'},
  {lat: 41.6786754, lng: -83.5127283, city: 'Toledo'},
  {lat: 33.4255056, lng: -111.9400125, city: 'Tempe'},
  {lat: 33.4255056, lng: -111.9400125, city: 'Tempe'},
  {lat: 27.9477595, lng: -82.458444, city: 'Tampa'},
  {lat: 30.4380832, lng: -84.2809332, city: 'Tallahassee'},
  {lat: 43.0481221, lng: -76.1474244, city: 'Syracuse'},
  {lat: 34.3076252, lng: -118.4492148, city: 'Los Angeles'},
  {lat: 37.3688301, lng: -122.0363496, city: 'Sunnyvale'},
  {lat: 30.4380832, lng: -84.2809332, city: 'Tallahassee'},
  {lat: 39.0016194, lng: -77.4032011, city: 'Sterling'},
  {lat: 29.6196787, lng: -95.6349463, city: 'Sugar Land'},
  {lat: 37.9577016, lng: -121.2907796, city: 'Stockton'},
  {lat: 36.1156306, lng: -97.0585717, city: 'Stillwater'},
  {lat: 37.4265407, lng: -122.1702926, city: 'undefined'},
  {lat: 41.0534302, lng: -73.5387341, city: 'Stamford'},
  {lat: 40.1853862, lng: -75.2276756, city: 'undefined'},
  {lat: 38.6268039, lng: -90.1994097, city: 'St. Louis'},
  {lat: 44.9504037, lng: -93.1015026, city: 'Saint Paul'},
  {lat: 30.0798826, lng: -95.4172549, city: 'undefined'},
  {lat: 42.0333694, lng: -87.7333972, city: 'Skokie'},
  {lat: 43.5499749, lng: -96.700327, city: 'Sioux Falls'},
  {lat: 37.6549493, lng: -122.4081251, city: 'South San Francisco'},
  {lat: 47.6038321, lng: -122.3300624, city: 'Seattle'},
  {lat: 47.6038321, lng: -122.3300624, city: 'Seattle'},
  {lat: 34.3541659, lng: -119.0592705, city: 'Santa Paula'},
  {lat: 37.050096, lng: -121.9905908, city: 'undefined'},
  {lat: 37.2333253, lng: -121.6846349, city: 'undefined'},
  {lat: 29.8826436, lng: -97.9405828, city: 'San Marcos'},
  {lat: 33.1433723, lng: -117.1661449, city: 'San Marcos'},
  {lat: 34.4221319, lng: -119.7026673, city: 'Santa Barbara'},
  {lat: 37.2333253, lng: -121.6846349, city: 'undefined'},
  {lat: 35.3540209, lng: -120.3757163, city: 'undefined'},
  {lat: 32.7174209, lng: -117.1627714, city: 'San Diego'},
  {lat: 32.7174209, lng: -117.1627714, city: 'San Diego'},
  {lat: 37.7790262, lng: -122.4199061, city: 'San Francisco'},
  {lat: 37.3361905, lng: -121.8905833, city: 'San Jose'},
  {lat: 37.7790262, lng: -122.4199061, city: 'San Francisco'},
  {lat: 37.504936, lng: -122.2618236, city: 'San Carlos'},
  {lat: 40.705141, lng: -111.869687, city: 'undefined'},
  {lat: 40.7596198, lng: -111.8867975, city: 'Salt Lake City'},
  {lat: 38.6268039, lng: -90.1994097, city: 'St. Louis'},
  {lat: 29.4246002, lng: -98.4951405, city: 'San Antonio'},
  {lat: 37.504936, lng: -122.2618236, city: 'San Carlos'},
  {lat: 38.5815719, lng: -121.4943996, city: 'Sacramento'},
  {lat: 42.0841936, lng: -88.0131275, city: 'Rolling Meadows'},
  {lat: 39.0840054, lng: -77.1527573, city: 'Rockville'},
  {lat: 44.0234387, lng: -92.4630182, city: 'Rochester'},
  {lat: 39.0840054, lng: -77.1527573, city: 'Rockville'},
  {lat: 37.5385087, lng: -77.43428, city: 'Richmond City'},
  {lat: 37.9357576, lng: -122.3477486, city: 'Richmond'},
  {lat: 32.9481789, lng: -96.7297206, city: 'Richardson'},
  {lat: 39.9547861, lng: -82.8121191, city: 'Reynoldsburg'},
  {lat: 37.7478572, lng: -84.2946539, city: 'Richmond'},
  {lat: 33.9533546, lng: -117.3961623, city: 'Riverside'},
  {lat: 37.4863239, lng: -122.2325233, city: 'Redwood City'},
  {lat: 33.8455911, lng: -118.3886766, city: 'Redondo Beach'},
  {lat: 37.4863239, lng: -122.2325233, city: 'Redwood City'},
  {lat: 40.2338438, lng: -111.6585337, city: 'Provo'},
  {lat: 41.8239891, lng: -71.4128343, city: 'Providence'},
  {lat: 35.7803977, lng: -78.6390989, city: 'Raleigh'},
  {lat: 40.3492744, lng: -74.6592958, city: 'Princeton'},
  {lat: 45.0065149, lng: -93.46651, city: 'Plymouth'},
  {lat: 45.5202471, lng: -122.6741949, city: 'Portland'},
  {lat: 40.4416941, lng: -79.9900861, city: 'Pittsburgh'},
  {lat: 37.3736298, lng: -122.219047, city: 'Portola Valley'},
  {lat: 33.4484367, lng: -112.0741417, city: 'Phoenix'},
  {lat: 39.9527237, lng: -75.1635262, city: 'Philadelphia'},
  {lat: 39.9527237, lng: -75.1635262, city: 'Philadelphia'},
  {lat: 34.1476452, lng: -118.1444779, city: 'Pasadena'},
  {lat: 37.4443293, lng: -122.1598465, city: 'Palo Alto'},
  {lat: 34.1476452, lng: -118.1444779, city: 'Pasadena'},
  {lat: 34.3664127, lng: -89.5187664, city: 'Oxford'},
  {lat: 34.5793131, lng: -118.1171108, city: 'Palmdale'},
  {lat: 37.4443293, lng: -122.1598465, city: 'Palo Alto'},
  {lat: 44.883607, lng: -68.672791, city: 'Orono'},
  {lat: 38.7011626, lng: -90.3623381, city: 'Overland'},
  {lat: 28.5421109, lng: -81.3790304, city: 'Orlando'},
  {lat: 37.8044557, lng: -122.2713563, city: 'Oakland'},
  {lat: 36.0103562, lng: -84.2696449, city: 'Oak Ridge'},
  {lat: 38.1061979, lng: -122.5681191, city: 'Novato'},
  {lat: 33.9412127, lng: -84.2135309, city: 'Norcross'},
  {lat: 42.8076585, lng: -73.8750206, city: 'undefined'},
  {lat: 33.7500378, lng: -117.8704931, city: 'undefined'},
  {lat: 36.1021436, lng: -115.1744946, city: 'undefined'},
  {lat: 40.7127281, lng: -74.0060152, city: 'undefined'},
  {lat: 41.3082138, lng: -72.9250518, city: 'New Haven'},
  {lat: 29.9499323, lng: -90.0701156, city: 'New Orleans'},
  {lat: 40.4862174, lng: -74.4518173, city: 'New Brunswick'},
  {lat: 40.29867, lng: -83.06797, city: 'undefined'},
  {lat: 35.845999, lng: -86.3920452, city: 'Murfreesboro'},
  {lat: 36.1622296, lng: -86.7743531, city: 'Nashville-Davidson'},
  {lat: 41.3082138, lng: -72.9250518, city: 'New Haven'},
  {lat: 42.2836393, lng: -71.346996, city: 'Natick'},
  {lat: 41.1224535, lng: -75.963861, city: 'Mountain Top'},
  {lat: 37.3893889, lng: -122.0832101, city: 'Mountain View'},
  {lat: 37.3893889, lng: -122.0832101, city: 'Mountain View'},
  {lat: 35.824341, lng: -78.8300321, city: 'Morrisville'},
  {lat: 34.1483499, lng: -118.0014517, city: 'Monrovia'},
  {lat: 39.0677546, lng: -104.8617588, city: 'Monument'},
  {lat: 40.4211798, lng: -79.7881025, city: 'Monroeville'},
  {lat: 42.1398188, lng: -71.516174, city: 'Milford'},
  {lat: 41.6619927, lng: -86.1586156, city: 'Mishawaka'},
  {lat: 44.9772995, lng: -93.2654692, city: 'Minneapolis'},
  {lat: 37.4112932, lng: -122.0541698, city: 'undefined'},
  {lat: 37.4282724, lng: -121.9066238, city: 'Milpitas'},
  {lat: 43.0962919, lng: -89.5119565, city: 'City of Middleton'},
  {lat: 25.9423108, lng: -80.239765, city: 'Miami Gardens'},
  {lat: 37.4519671, lng: -122.1779927, city: 'Menlo Park'},
  {lat: 25.7742658, lng: -80.1936589, city: 'Miami'},
  {lat: 35.1490215, lng: -90.0516285, city: 'Memphis'},
  {lat: 37.1641544, lng: -120.7678602, city: 'undefined'},
  {lat: 42.4184296, lng: -71.1061639, city: 'Medford'},
  {lat: 18.2011161, lng: -67.1391124, city: 'Mayagüez'},
  {lat: 42.9956397, lng: -71.4547891, city: 'Manchester'},
  {lat: 41.7754814, lng: -72.2148133, city: 'undefined'},
  {lat: 43.074761, lng: -89.3837613, city: 'Madison'},
  {lat: 33.5635206, lng: -101.879336, city: 'Lubbock'},
  {lat: 38.0464066, lng: -84.4970393, city: 'Lexington'},
  {lat: 40.7415095, lng: -73.9569751, city: 'New York'},
  {lat: 42.4473175, lng: -71.2245003, city: 'Lexington'},
  {lat: 40.8088861, lng: -96.7077751, city: 'Lincoln'},
  {lat: 33.046233, lng: -96.994174, city: 'Lewisville'},
  {lat: 43.643032, lng: -72.251587, city: 'Lebanon'},
  {lat: 34.0536909, lng: -118.2427666, city: 'Los Angeles'},
  {lat: 33.9562149, lng: -83.9879625, city: 'Lawrenceville'},
  {lat: 41.673642, lng: -88.0017261, city: 'Lemont'},
  {lat: 27.5199841, lng: -99.4953764, city: 'Laredo'},
  {lat: 36.0028345, lng: -115.2012991, city: 'undefined'},
  {lat: 40.1745538, lng: -74.9226665, city: 'Langhorne'},
  {lat: 42.892289, lng: -76.1052019, city: 'undefined'},
  {lat: 32.83259, lng: -117.2716839, city: 'undefined'},
  {lat: 33.6469261, lng: -117.6859213, city: 'Lake Forest'},
  {lat: 47.7567679, lng: -122.2809623, city: 'Lake Forest Park'},
  {lat: 35.9603948, lng: -83.9210261, city: 'Knoxville'},
  {lat: 41.4803791, lng: -71.5225598, city: 'undefined'},
  {lat: 33.6856969, lng: -117.8259819, city: 'Irvine'},
  {lat: 42.4396039, lng: -76.4968019, city: 'Ithaca'},
  {lat: 38.0226393, lng: -78.3591748, city: 'undefined'},
  {lat: 39.7683331, lng: -86.1583502, city: 'Indianapolis'},
  {lat: 29.7589382, lng: -95.3676974, city: 'Houston'},
  {lat: 46.8591287, lng: -88.7774734, city: 'undefined'},
  {lat: 40.4959379, lng: -74.4243178, city: 'Highland Park'},
  {lat: 36.0301134, lng: -114.9826194, city: 'Henderson'},
  {lat: 37.6688205, lng: -122.0807964, city: 'Hayward'},
  {lat: 40.2070573, lng: -74.0812506, city: 'undefined'},
  {lat: 43.703622, lng: -72.288666, city: 'Hanover'},
  {lat: 41.2889866, lng: -72.6817616, city: 'undefined'},
  {lat: 34.1489805, lng: -82.112882, city: 'undefined'},
  {lat: 34.851354, lng: -82.3984882, city: 'Greenville'},
  {lat: 42.9632405, lng: -85.6678639, city: 'Grand Rapids'},
  {lat: 33.1020053, lng: -98.581341, city: 'Graham'},
  {lat: 39.1434406, lng: -77.2013705, city: 'Gaithersburg'},
  {lat: 39.4142186, lng: -77.4109269, city: 'Frederick'},
  {lat: 40.5508527, lng: -105.0668085, city: 'Fort Collins'},
  {lat: 29.6519396, lng: -82.3249961, city: 'Gainesville'},
  {lat: 42.888627, lng: -88.0384195, city: 'Franklin'},
  {lat: 40.5508527, lng: -105.0668085, city: 'Fort Collins'},
  {lat: 35.1987522, lng: -111.6518229, city: 'Flagstaff'},
  {lat: 36.0625843, lng: -94.1574328, city: 'Fayetteville'},
  {lat: 42.0447388, lng: -87.6930459, city: 'Evanston'},
  {lat: 46.877229, lng: -96.789821, city: 'Fargo'},
  {lat: 41.5514979, lng: -70.6147519, city: 'Falmouth'},
  {lat: 37.8314089, lng: -122.2865266, city: 'Emeryville'},
  {lat: 41.6012796, lng: -85.8590603, city: 'undefined'},
  {lat: 42.7355416, lng: -84.4852469, city: 'East Lansing'},
  {lat: 31.7754152, lng: -106.4646348, city: 'El Paso'},
  {lat: 43.134564, lng: -70.927048, city: 'Durham'},
  {lat: 35.996653, lng: -78.9018053, city: 'Durham'},
  {lat: 35.996653, lng: -78.9018053, city: 'Durham'},
  {lat: 34.1394513, lng: -117.9772873, city: 'Duarte'},
  {lat: 25.8184725, lng: -80.3539679, city: 'Doral'},
  {lat: 42.3315509, lng: -83.0466403, city: 'Detroit'},
  {lat: 26.318342, lng: -80.0996306, city: 'Deerfield Beach'},
  {lat: 39.7392364, lng: -104.9848623, city: 'Denver'},
  {lat: 38.545379, lng: -121.7445835, city: 'Davis'},
  {lat: 42.5750946, lng: -70.9300507, city: 'Danvers'},
  {lat: 26.075729, lng: -80.284109, city: 'Davie'},
  {lat: 40.3099385, lng: -74.5177224, city: 'Cranbury Township'},
  {lat: 38.545379, lng: -121.7445835, city: 'Davis'},
  {lat: 39.2156213, lng: -76.8582049, city: 'undefined'},
  {lat: 30.5955289, lng: -96.3071042, city: 'College Station'},
  {lat: 35.2521996, lng: -92.6888936, city: 'undefined'},
  {lat: 44.5645659, lng: -123.2620435, city: 'Corvallis'},
  {lat: 38.980666, lng: -76.9369189, city: 'College Park'},
  {lat: 34.6850749, lng: -82.8364111, city: 'Clemson'},
  {lat: 42.1487043, lng: -72.6078672, city: 'Chicopee'},
  {lat: 41.8755616, lng: -87.6244212, city: 'Chicago'},
  {lat: 35.0457219, lng: -85.3094883, city: 'Chattanooga'},
  {lat: 41.8755616, lng: -87.6244212, city: 'Chicago'},
  {lat: 34.0966764, lng: -117.7197785, city: 'Claremont'},
  {lat: 38.029306, lng: -78.4766781, city: 'undefined'},
  {lat: 38.029306, lng: -78.4766781, city: 'undefined'},
  {lat: 33.1580933, lng: -117.3505966, city: 'Carlsbad'},
  {lat: 40.2625702, lng: -80.1872797, city: 'Canonsburg'},
  {lat: 42.3778749, lng: -71.0619957, city: 'Boston'},
  {lat: 42.3750997, lng: -71.1056157, city: 'Cambridge'},
  {lat: 35.9131542, lng: -79.05578, city: 'Chapel Hill'},
  {lat: 42.3750997, lng: -71.1056157, city: 'Cambridge'},
  {lat: 44.4762975, lng: -73.2135472, city: 'Burlington'},
  {lat: 37.687165, lng: -122.4027942, city: 'Brisbane'},
  {lat: 44.311461, lng: -96.79844, city: 'Brookings'},
  {lat: 40.5598127, lng: -74.5517146, city: 'Bridgewater Township'},
  {lat: 42.8867166, lng: -78.8783922, city: 'Buffalo'},
  {lat: 37.5841026, lng: -122.3660825, city: 'Burlingame'},
  {lat: 42.3602534, lng: -71.0582912, city: 'Boston'},
  {lat: 42.3602534, lng: -71.0582912, city: 'Boston'},
  {lat: 43.8764706, lng: -69.6336571, city: 'Boothbay'},
  {lat: 42.5584264, lng: -71.268947, city: 'Billerica'},
  {lat: 43.4925843, lng: -70.4533844, city: 'Biddeford'},
  {lat: 39.1670396, lng: -86.5342881, city: 'Bloomington'},
  {lat: 38.9848265, lng: -77.0946459, city: 'Bethesda'},
  {lat: 38.9848265, lng: -77.0946459, city: 'Bethesda'},
  {lat: 37.8708393, lng: -122.2728639, city: 'Berkeley'},
  {lat: 37.8708393, lng: -122.2728639, city: 'Berkeley'},
  {lat: 39.2908816, lng: -76.610759, city: 'Baltimore'},
  {lat: 42.4917301, lng: -71.2817947, city: 'Bedford'},
  {lat: 30.4459596, lng: -91.18738, city: 'Baton Rouge'},
  {lat: 39.2908816, lng: -76.610759, city: 'Baltimore'},
  {lat: 30.2711286, lng: -97.7436995, city: 'Austin'},
  {lat: 30.2711286, lng: -97.7436995, city: 'Austin'},
  {lat: 33.7490987, lng: -84.3901849, city: 'Atlanta'},
  {lat: 39.729432, lng: -104.8319196, city: 'Aurora'},
  {lat: 33.9597677, lng: -83.376398, city: 'Athens'},
  {lat: 33.7490987, lng: -84.3901849, city: 'Atlanta'},
  {lat: 34.1742611, lng: -97.1436254, city: 'Ardmore'},
  {lat: 33.9597677, lng: -83.376398, city: 'Athens'},
  {lat: 42.2681569, lng: -83.7312291, city: 'Ann Arbor'},
  {lat: 42.2681569, lng: -83.7312291, city: 'Ann Arbor'},
  {lat: 61.2163129, lng: -149.8948523, city: 'Anchorage'},
  {lat: 42.0267703, lng: -93.6170554, city: 'Ames'},
  {lat: 61.2163129, lng: -149.8948523, city: 'Anchorage'},
  {lat: 35.0841034, lng: -106.6509851, city: 'Albuquerque'},
  {lat: 39.0030666, lng: -76.9721023, city: 'Adelphi'},
  {lat: 39.0030666, lng: -76.9721023, city: 'Adelphi'},
  {lat: 33.6874388, lng: -80.4363743, city: 'undefined'},
  {lat: 41.8239891, lng: -71.4128343, city: 'Providence'},
  {lat: 27.7567667, lng: -81.4639835, city: 'undefined'},
  {lat: 40.7127281, lng: -74.0060152, city: 'undefined'},
  {lat: 42.3636331, lng: -87.8447938, city: 'Waukegan'},
  {lat: 42.3709299, lng: -71.1828321, city: 'Watertown'},
  {lat: 27.9518491, lng: -82.4563972, city: 'Tampa'},
  {lat: 40.1853862, lng: -75.2276756, city: 'undefined'},
  {lat: 40.7596198, lng: -111.8867975, city: 'Salt Lake City'},
  {lat: 37.9020731, lng: -122.0618702, city: 'Walnut Creek'},
  {lat: 37.6549493, lng: -122.4081251, city: 'South San Francisco'},
  {lat: 42.8688613, lng: -112.4401098, city: 'Pocatello'},
  {lat: 29.4246002, lng: -98.4951405, city: 'San Antonio'},
  {lat: 33.9533546, lng: -117.3961623, city: 'Riverside'},
  {lat: 33.5965685, lng: -117.659405, city: 'Mission Viejo'},
  {lat: 43.074761, lng: -89.3837613, city: 'Madison'},
  {lat: 40.4416941, lng: -79.9900861, city: 'Pittsburgh'},
  {lat: 30.3321838, lng: -81.655651, city: 'Jacksonville'},
  {lat: 42.4396039, lng: -76.4968019, city: 'Ithaca'},
  {lat: 47.9078244, lng: -97.0592028, city: 'Grand Forks'},
  {lat: 41.6612561, lng: -91.5299106, city: 'Iowa City'},
  {lat: 29.299328, lng: -94.7945882, city: 'Galveston'},
  {lat: 39.7683331, lng: -86.1583502, city: 'Indianapolis'},
  {lat: 33.6856969, lng: -117.8259819, city: 'Irvine'},
  {lat: 39.4142186, lng: -77.4109269, city: 'Frederick'},
  {lat: 37.8314089, lng: -122.2865266, city: 'Emeryville'},
  {lat: 40.102555, lng: -105.3632079, city: 'undefined'},
  {lat: 34.1684364, lng: -118.6058382, city: 'Los Angeles'},
  {lat: 42.2625932, lng: -71.8022934, city: 'Worcester'},
  {lat: 39.9597213, lng: -75.6059638, city: 'West Chester'},
  {lat: 36.1556805, lng: -95.9929113, city: 'Tulsa'},
  {lat: 46.2055155, lng: -118.5174284, city: 'undefined'},
  {lat: 36.1556805, lng: -95.9929113, city: 'Tulsa'},
  {lat: 32.2228765, lng: -110.9748477, city: 'Tucson'},
  {lat: 40.1229002, lng: -74.8644706, city: 'Bristol Township'},
  {lat: 41.0762077, lng: -73.8587461, city: 'Tarrytown'},
  {lat: 43.0481221, lng: -76.1474244, city: 'Syracuse'},
  {lat: 44.5229223, lng: -89.574111, city: 'Stevens Point'},
  {lat: 33.7445499, lng: -85.2880077, city: 'Tallapoosa'},
  {lat: 40.5834557, lng: -74.1496048, city: 'New York'},
  {lat: 37.3688301, lng: -122.0363496, city: 'Sunnyvale'},
  {lat: 44.9504037, lng: -93.1015026, city: 'Saint Paul'},
  {lat: 38.6268039, lng: -90.1994097, city: 'St. Louis'},
  {lat: 39.0273676, lng: -95.7627535, city: 'undefined'},
  {lat: 44.9504037, lng: -93.1015026, city: 'Saint Paul'},
  {lat: 43.5962264, lng: -70.3300557, city: 'Scarborough'},
  {lat: 42.4648183, lng: -71.010051, city: 'Saugus'},
  {lat: 47.6694141, lng: -122.1238767, city: 'Redmond'},
  {lat: 36.6744117, lng: -121.6550372, city: 'Salinas'},
  {lat: 29.8826436, lng: -97.9405828, city: 'San Marcos'},
  {lat: 35.7803977, lng: -78.6390989, city: 'Raleigh'},
  {lat: 40.3492744, lng: -74.6592958, city: 'Princeton'},
  {lat: 28.5479786, lng: -81.4127842, city: 'Orlando'},
  {lat: 39.6838714, lng: -75.7354367, city: 'Newark'},
  {lat: 40.2806668, lng: -74.0954182, city: 'undefined'},
  {lat: 45.5202471, lng: -122.6741949, city: 'Portland'},
  {lat: 29.9499323, lng: -90.0701156, city: 'New Orleans'},
  {lat: 37.3361905, lng: -121.8905833, city: 'San Jose'},
  {lat: 40.0811745, lng: -82.8087864, city: 'New Albany'},
  {lat: 45.1068949, lng: -93.2075931, city: 'Mounds View'},
  {lat: 39.6296809, lng: -79.9559437, city: 'Morgantown'},
  {lat: 46.7323875, lng: -117.0001651, city: 'Moscow'},
  {lat: 31.9973662, lng: -102.0779482, city: 'Midland'},
  {lat: 36.7988827, lng: -121.7865811, city: 'undefined'},
  {lat: 37.4519671, lng: -122.1779927, city: 'Menlo Park'},
  {lat: 40.3977612, lng: -105.0749801, city: 'Loveland'},
  {lat: 35.1490215, lng: -90.0516285, city: 'Memphis'},
  {lat: 30.2240897, lng: -92.0198427, city: 'Lafayette'},
  {lat: 38.9719384, lng: -95.2359496, city: 'Lawrence'},
  {lat: 35.9603948, lng: -83.9210261, city: 'Knoxville'},
  {lat: 41.6731523, lng: -93.6973395, city: 'Johnston'},
  {lat: 34.0484039, lng: -106.8947492, city: 'Socorro'},
  {lat: 34.0536909, lng: -118.2427666, city: 'Los Angeles'},
  {lat: 44.8560029, lng: -123.1913034, city: 'Independence'},
  {lat: 40.5118496, lng: -80.1040429, city: 'Emsworth'},
  {lat: 36.0726355, lng: -79.7919754, city: 'Greensboro'},
  {lat: 43.0123975, lng: -78.9698151, city: 'undefined'},
  {lat: 45.5228939, lng: -122.989827, city: 'Hillsboro'},
  {lat: 39.1434406, lng: -77.2013705, city: 'Gaithersburg'},
  {lat: 29.7589382, lng: -95.3676974, city: 'Houston'},
  {
    lat: 29.6735776,
    lng: -82.3233746,
    city: 'City of Gainesville Municipal Boundaries',
  },
  {lat: 42.3315509, lng: -83.0466403, city: 'Detroit'},
  {lat: 44.671539, lng: -70.1441014, city: 'undefined'},
  {lat: 39.7348381, lng: -104.9653271, city: 'undefined'},
  {lat: 32.7762719, lng: -96.7968559, city: 'Dallas'},
  {lat: 39.8454163, lng: -88.9524151, city: 'Decatur'},
  {lat: 34.0007493, lng: -81.0343313, city: 'Columbia'},
  {lat: 39.1014537, lng: -84.5124602, city: 'Cincinnati'},
  {lat: 37.1298517, lng: -80.4089389, city: 'Christiansburg'},
  {lat: 34.0966764, lng: -117.7197785, city: 'Claremont'},
  {lat: 35.6507888, lng: -117.66173, city: 'undefined'},
  {lat: 35.9131542, lng: -79.05578, city: 'Chapel Hill'},
  {lat: 32.7876012, lng: -79.9402728, city: 'Charleston'},
  {lat: 37.5841026, lng: -122.3660825, city: 'Burlingame'},
  {lat: 40.8466508, lng: -73.8785937, city: 'New York'},
  {lat: 33.1580933, lng: -117.3505966, city: 'Carlsbad'},
  {lat: 33.9170444, lng: -117.8888557, city: 'Brea'},
  {lat: 43.6166163, lng: -116.200886, city: 'Boise'},
  {lat: 42.4917301, lng: -71.2817947, city: 'Bedford'},
  {lat: 41.3098135, lng: -72.9299074, city: 'New Haven'},
  {lat: 35.6009498, lng: -82.5540161, city: 'Asheville'},
  {lat: 42.3803676, lng: -72.523143, city: 'Amherst'},
  {lat: 42.0267703, lng: -93.6170554, city: 'Ames'},
  {lat: 39.7837304, lng: -100.4458825, city: 'undefined'},
  {lat: 37.6090291, lng: -121.899142, city: 'undefined'},
]

export const QUOTE_ICON_DATA: Array<QuoteCardProps> = [
  {
    quote: `There's days we have Opentrons transfer 800 samples. I would never be able to do as many samples by hand.`,
    iconName: 'quote',
    name: 'Kaja Wasik',
    schoolName: 'Gencove',
  },
  {
    quote:
      'The whole setup process was easy and your online guide was fantastic.',
    iconName: 'quote',
    name: 'Tom Huckvale',
    schoolName: 'YouSeq',
    blogUrl:
      'https://blog.opentrons.com/opentrons-user-interview-with-youseqs-tom-huckvale/',
  },
  {
    quote: 'Our life is so much easier now because the OT-2 is really good.',
    iconName: 'quote',
    name: 'Marilene Pavan',
    schoolName: 'DAMP Lab',
  },
  {
    quote: `The sky's the limit in terms of customization you could do... that would be impossible to do on any other commercially available system.`,
    iconName: 'quote',
    name: 'Scott Farber',
    schoolName: 'Dana-Farber Cancer Institute',
  },
  {
    quote:
      'Since [the robot] can run a lot of my Bradfords and other assays I do on a regular basis, I have so much free time.',
    iconName: 'quote',
    name: 'Elaine Phan',
    schoolName: 'BioMarin',
  },
  {
    quote:
      'Before, I had to leave one whole day for one ELISA run. Now, the robot frees my hands.',
    iconName: 'quote',
    name: 'Annabel Shang',
    schoolName: 'MilliporeSigma',
    blogUrl:
      'https://blog.opentrons.com/opentrons-user-interview-with-milliporesigma-annabel-shang/',
  },
  {
    quote:
      'The OT-2 is invaluable, quite frankly. I couldn’t do my job without it.',
    iconName: 'quote',
    name: 'N. Brown',
    schoolName: 'Skincare Startup',
  },
  {
    quote: 'It is going to make our lives so much easier.',
    iconName: 'quote',
    name: 'Arianna Morosetti',
    schoolName: 'University of Calgary',
  },
  {
    quote:
      'The OT-2 is very handy for me. The only manual step is transferring tubes into the OT-2; I just put the tubes in and press "Go."',
    iconName: 'quote',
    name: 'Xian Weng Jiang',
    schoolName: 'Cytera Cellworks',
    blogUrl:
      'https://blog.opentrons.com/opentrons-user-interview-with-dr-xian-weng-jiang-cytera-cellworks/',
  },
]
